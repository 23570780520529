import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_CONTACT } from "../../../../common/data/SecD3v";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";

import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import { checkmark } from "react-icons-kit/ionicons/checkmark";
import SectionArea, {
  Row,
  Col,
  StatusDetails,
  StatusComponents,
  StatusComponentsContainer,
  ComponentContents,
  ColumnContents,
} from "./section.style";

const statusLabels = (statusLabel) => {
  console.log(statusLabel);
  switch (statusLabel) {
    case "major_outage":
      return "Outage";
    case "under_maintenance":
      return "Under Maintenance";
    case "operational":
      return "No Known Issues";
    case "degraded_performance":
      return "Impact On Services";
    case "partial_outage":
      return "Impact On Services";
    default:
      return "NA";
  }
};

const Section = () => {
  const { sections } = SECTION_CONTACT;

  const [statusDescription, setStatusDescription] = useState("NA");
  const [statusPageURL, setStatusPageURL] = useState();
  const [statusPageDate, setStatusPageDate] = useState();
  const [statusComponents, setStatusComponents] = useState();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return (
      new Date(dateString).toLocaleDateString("en-AU") +
      " " +
      new Date(dateString).toLocaleTimeString("en-AU")
    );
  };

  useEffect(() => {
    axios
      .get(
        "https://42sdws3v9s9q.statuspage.io/api/v2/summary.json?api_key=35d0416c-2936-43f7-bf32-20732406303a"
      )
      .then((res) => {
        setStatusDescription(res.data.status.description);
        setStatusPageURL(res.data.page.url);
        setStatusPageDate(res.data.page.updated_at);
        setStatusComponents(res.data.components);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <ColumnContents>
      <SectionArea id="contact">
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Box className="blockTitle">
                <a className="buttonStatus">
                  <span>{statusDescription}</span>
                </a>
              </Box>
              {/* <StatusDetails>
                <strong>URL</strong>: {statusPageURL}
              </StatusDetails> */}
              <StatusDetails>
                <strong>Updated</strong>: {formatDate(statusPageDate)}
              </StatusDetails>
              <StatusComponentsContainer>
                {statusComponents?.map((components) => (
                  <StatusComponents key={components.id}>
                    <ComponentContents>
                      <strong>{components.name}</strong>
                    </ComponentContents>
                    <ComponentContents>
                      <span className={components.status}></span>
                      {statusLabels(components.status)}
                    </ComponentContents>
                  </StatusComponents>
                ))}
              </StatusComponentsContainer>
            </Box>
          </Row>
          <Row>
            <Box className="blockTitle">
              <a className="button" href="mailto:enquiries@secd3v.com.au">
                <span>
                  Email us
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </a>
            </Box>
          </Row>
        </Container>
      </SectionArea>
    </ColumnContents>
  );
};

export default Section;
